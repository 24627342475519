html,
body,
#root {
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  background-color: #eeeeee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none !important;
}
